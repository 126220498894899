import EndQuestion from './endQuestion'
import './pages.scss'
export default function OurCredo(){
    return (
        <div className="pageContainer">
            <div className='pageContent'>
<p>
    <div className="hugeCallout">The NullWest Credo</div>
</p>
<p>
The following is a statement of the beliefs that drive our work.  They are not intended 
as a legal document, but rather to define the spirit and goals to which we aspire.</p>
<h2>Absolute transparency and honesty.</h2>  
<p>We believe in it, and we demand it.  We do everything with total transparency. Every cost. Every deadline. Every line of code. Visible 
to all team members and clients at any time in the project.  Creating amazing work is 
complicated enough already.
</p>

<h2>Measure Twice, Cut Once</h2>  
<p> We take the time to build it right, utilizing  a blend of research, experimentation, and quality testing. This approach ensures we deliver robust, finely-crafted solutions tailored to your needs.  Need an accelerated timeline? We can deliver by being  meticulous on product scope and scaling the team, while avoiding cutting corners.
</p>
<h2>Innovators</h2>
<p>We aim to be a beacon of innovation, adapting and thriving along with our clients in the ever-changing tech landscape. Each team member pursues lifelong learning objectives, taking classes intended to help meet today's needs and anticipate tomorrow's challenges. In addition, we hold quarterly hackathons to trial and play with emerging tech, and encourage our clients to participate. </p>

<h2>10% Overdrive</h2> 
<p>Sometimes you need to work late into the night to get the project 
done. However, our team is talented because they are well rounded and have productive, happy lives outside of work.  Therefore we restrict overtime to only 10% of the 
total project time.</p>

<h2>Community</h2>
<p>We’re in this together.  Let’s try to build a community, and add to the 
communities we are in. </p>
<p>
<h2>Build in the End Game</h2> All digital products have shelf lives and need maintenance.  By 
acknowledging this fact as part of our process, we are able to create products that include the appropriate content management, cyclical improvements  due to technology 
changes, and an estimate of if and when it is time to end the project.</p>
<h2>No Equity</h2>
<p>
 It is great to share in the ownership of a product, but equity creates too 
many unnecessary complications. What you pay us to research, what you pay us to 
build, you own fully.  We believe that if you like the work, you will keep us included 
regardless of equity.</p>
<h2>No Hype</h2>
 <p>
Our industry is plagued by over-enthusiasm and buzzwords.  We focus on 
staying grounded to reality in our planning, while being visionary in our expectations.</p>
{/* <h2>Debt Free</h2>
<p>
 Our company operates without debt.  We can not commence work until we 
have the money to pay for our staff, nor can we continue work without appropriate 
payments.  For this reason, we strive to create a unique payment structure with each 
client that addresses our concerns and theirs.</p> */}
<EndQuestion question="Curious to learn more about NullWest?" buttonText="get in touch" link="/get-in-touch" />
        </div></div>
    )
}