import { JsxElement } from 'typescript';
import './overlay.scss';
import { ReactNode } from 'react';

export default function Overlay({onClose, children}:{onClose: Function, children?:ReactNode}){

    const closeOverlay = (e:any)=>{
        if(e.target === e.currentTarget){
            onClose()
        }
    }
    return (<div className='overlay' onClick={closeOverlay}>
        <div className="closeButton" onClick={()=>onClose()} aria-label='Closes the overlay'>x</div>
        <div className="content">
            {children}
        </div>
    </div>)
}