import ProjectSlider from '../projectSlider'
export default function Home(){

            
    const servicesData = [
        {
            img:{
                src:"/assets/home/buildYourProduct.jpeg",
                alt:"Build Your Product",
            },
            title: 'Build Your Product',
            description: "We specialize in the development of innovative, user-friendly mobile apps and websites. We thrive on solving complex problems with elegant solutions, delivering high-quality results tailored to your needs.",
            cta: {
                label: "View Our Work",
                link: "/case-studies"
            }
        },
        {
            img:{
                src:"/assets/home/leadYourTeam.jpeg",
            },
            title: 'Lead Your Team',
            description: "Whether you're looking for strategic advice, help with hiring, super charge a project with a technical product manager, or bring in a fractional CTO to lead your technologies, we've got you covered.",
            cta: {
                label: "Meet our Founders",
                link: "/meet-the-founders"
            }
        },
        {
            img:{
                src:"/assets/home/inventYourFuture.jpeg",
            },
            title: 'Invent Your Future',
            description: "AI, VR, blockchain, and other new technologies can be either a fad or a game changer to your industry. We cut through the hyperbole and spearhead your digital transformation through research focused prototypes and implementation that proves its value.",
            cta: {
                label: "View our Approach",
                link: "/process-capabilities"
            }
        }
    ]

    return (
        <div className="pageContainer">
            <div className="headerImg">
                <img src="/assets/pageImages/homescreen.png" alt="Brilliant Software. Built to Last. Image of a marble statue wearing a golden VR headset." />
            </div>
        <div className='pageContent'>
        <div className="hugeCallout">Hello World, we are NullWest.</div>
         <div>
         <p>We are old-school craftsmen in the digital age. We develop software and help others build their own amazing products. 
            We use best-in-class software practices with a focus on communication and transparency keeping you informed at each stage, 
            while remaining mindful of time and cost efficiency.
         </p>
            </div>
         
        <div className="hugeCallout pb-2">What we do ...</div>
        <ProjectSlider projects={servicesData} />
        </div>
        </div>
    )
}