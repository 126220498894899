import './footer.scss'

export default function Footer(){
    return (<div className="footer desktop">
        <div>
            <span><a href="tel:+1425243-6451"><img src="/icons/phone.svg"/> +1 (425) 243-6451</a></span>
            <span><a href="mailto:hello@nullwest.com"><img src="/icons/email.svg"/> hello@nullwest.com</a></span>
            <span><a href="mailto:hello@nullwest.com"><img src="/icons/map.svg"/>Bellingham | Seattle | Tacoma, Washington</a></span>
        </div>
    </div>)
}