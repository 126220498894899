import './projectSlider.scss';

import { navigate } from "wouter/use-browser-location"
import TextButton from "../buttons/textButton"

export type ServicesData = {
    img:{
        src: string,
        alt?:string,
    },
    title: string,
    description?:string,
    cta?: {
        label: string,
        link: string,
    }
}

export default function ProjectSlider ({projects, imagePosition}: {projects:ServicesData[], imagePosition?:string}){
    const printedProjects =  projects.map(project=>{
    const {title, description, cta,img} = project;
    return (
    <div className="serviceContainer" key={title}>
                <div className="imageContainer">
                    <img src={img.src} alt={img.alt||title}             
                        style={{ objectPosition: imagePosition || 'center' }} 
                    />
                </div>
                <div className="title"><span>{title}</span></div>
                <div className="imgOverlay" onClick={()=> {
                    if(cta){
                        navigate(cta.link, {replace:true})
                    }
                    }
                }>
                    <strong>{title}</strong>
                    {description && description}
                    {cta && 
                        <div className="pt-4">
                            <TextButton text={cta.label} handleClick={()=>{
                                //do nothing
                            }}/>
                        </div>
                    }
            </div>
    </div>)})

    return (
        <div className='projectSlider'>
            <div className='imageRow'>
                {printedProjects}
            </div>
        </div>
    )
}