import './caseStudy.scss';
import ProjectSlider, {ServicesData} from '../projectSlider';
import { CaseStudyType } from '../../data/case-studies';


const summaryCard = ({image_carousel, title, skills, statement}:CaseStudyType)=>{
    const linkUrl = `/case-studies/${title.replaceAll(' ', '-')}`;
    const caseStudy: ServicesData = {
        title,
        img: image_carousel[0],
        description: statement,
        cta:{
            label: 'Learn More',
            link: linkUrl
        }
    }

    return <ProjectSlider projects={[caseStudy]}/>
}


const showFullView = ({image_carousel, tagline, client, ourRole, challenges, solutions, results, skills}:CaseStudyType)=>{
    return (
        <div className="caseStudyContainer">
            {tagline && <div className="tagline">{tagline}</div>}
            <div className="carousel"><img src={image_carousel[0].src} alt={image_carousel[0].alt} /></div>
            
            {[client, ourRole, solutions, results].map((stage, idx)=>{
                if(!stage) return null;
                return (<div key={`step_${idx}`}>
                    <h2>{stage.title}</h2>
                    {stage.description}
                    {stage.image &&  
                    <div className="center">
                        <img src={stage.image.img_url} alt={stage.image.alt_text||'Product image'} />
                    </div> 
                    }
                </div>
                )
            })}

        {challenges && 
             <> 
                <h2>Core Challenges</h2>
                {challenges.map((challenge, idx)=>{
                    return (<div className="challenge" key={idx+"_challenge"}>
                        <div className='title'>"{challenge.title}"
                        </div>
                        <div className="description">
                            {challenge.description}
                        </div>
                    </div>
                )   
                })}
            </>
        }
          
            <div>
                <h2>Tech Stack</h2>
                <div className="techStack">
                    <ul>
                    {skills.map(skill=><li>{skill}</li>)}
                    </ul>
                </div>
            </div>
        </div>

    )
}
export default function CaseStudy({caseStudyData, showSummary = true}:{caseStudyData:CaseStudyType, showSummary?: boolean}){
    return showSummary?summaryCard(caseStudyData):showFullView(caseStudyData)
}