export type CaseStudyType = {
    title: string,
    statement: string,
    tagline?: string,
    skills: string[],
    image_carousel: {
        src: string,
        alt?: string,
    }[],
    client?: {title: string, description: React.ReactNode, image?: {img_url: string, alt_text:string}},
    ourRole?: {title: string, description: React.ReactNode, image?: {img_url: string, alt_text:string}},
    challenges?: {title: string, description: React.ReactNode }[],
    solutions?: {title: string, description: React.ReactNode, image?: {img_url: string, alt_text:string}},
    results?: {title: string, description: React.ReactNode, image?: {img_url: string, alt_text:string}},
        //  next_project:{ title: string, link: url, image:url, description:string}
}



const Xembly = {
    // XEMBLY
    title: "AI Executive Assistant",
    tagline: '"Automate your work life with an AI Chief of Staff"',
    statement:"Xembly is an AI based executive assistant that performs hundreds of tasks including: attending meetings, writing summaries and assigning tasks, and handling complex scheduling and planning. We built the front end, Slack interfaces, and integrated the AI chatbot.",
    skills:['NextJs', 'Tailwind', 'NodeJS', 'NestJS', 'Jest', 'PostGRES', 'Kubernetes', 'Python', 'Slack API', 'Google API', 'OpenAI API',],
    image_carousel:[{src:'/assets/case-studies/xembly/reading-the-future.webp', alt:"Xembly AI is conversing with a customer"}],
    client: {
        title: "About Xembly",
        description: (<>
         <p>Xembly revolutionized the way professional meetings are managed using AI assistants. Trusted by clients like Docker, Yahoo, and Qualtrics, Xembly offered:</p>
        <div className="youtube-container center">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/Lxl7UHq_4oo?si=5FoSboB5Kk_AO3YR" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
            <h2>Key Features</h2> 
            <ul>
                <li>Voiceprint AI for accurate meeting notetaking.</li>
                <li>Automatic meeting summarizations and action item assignments.</li>
                <li>Effortless calendar synchronization and meeting scheduling.</li>
                <li>Seamless integration with Slack for easy sharing and scheduling.</li>
                <li>Xena, an AI chatbot providing all functionalities via chat.</li>
                <li>Chrome Extension for Google Calendar integration.</li>
                <li>A responsive mobile and desktop website for task and meeting management.</li>
            </ul>
            </>
            ),
    },
    ourRole: {
        title: "Our Roles",
        description:(<>
        <p>We were instrumental in building many of the key software products for Xembly. This includes the UI for the flagship website, chat system, task management, and calendar displays on web, mobile and chrome extension.</p>
        <div className="center">
                <img src="/assets/case-studies/xembly/mobile-tasks.png" alt="mobile phone with AI generated tasks"/>
            </div>
         <p>
            We collaborated with Xembly team members to develop recommendation algorithms, database archicture, AI pipelines,  and a Slack app  .
            Scott, as the Founding Engineer, and Front End tech lead, was responsible for ensuring quality across all client facing products.
        </p> 
        <div className="center">
                <img src="/assets/case-studies/xembly/bookthechannel.avif" alt="mobile phone with AI generated tasks"/>
            </div>
        </>)
    },
    challenges: [
        {
            title:  "We need rapid adoption of our software",
            description: (
            <>
            <p>To ensure quick adoption, Xembly integrated with existing software like Google, Slack, and Zoom. Using the JS ecosystem and iFrames, we ensured reusable code and minimized additional work.</p>
            <p>A robust test suite and monitoring with Sentry guaranteed operational consistency as new features were deployed.</p>
            <div><img src="/assets/case-studies/xembly/bookAMeeting.jpg" alt="an executive books a meeting through her phone using Xena chat system."/></div>
            </>)
        },
        {
            title:  "Work calendars are the epicenter of productivity, do not mess it up",
            description: (
            <>
            <p>Handling client calendars required high security standards. By abstracting customer data and creating sample personas, we tested new algorithms without disrupting actual users.</p>
            <div><img src="/assets/case-studies/xembly/calendar.png" alt="Xembly Calendar."/></div>
            </>)
        },
        {
            title:  "Users have already been trained on productivity tools",
            description: (
            <>
           <p>Xembly's UI had to match professional services in responsiveness and user-friendliness. Building with NextJS and Tailwind, along with frequent team collaboration, ensured high-quality UI.</p>
            </>)
        },
    ]
};

const Creators = {
    // Creators Project
    title: "The Creators Project",
    tagline: '"A global party to launch a new multi-platform experience from Vice Media and Intel"',
    statement:"The Creators Project was a global initiative by Vice Media and Intel, aimed at celebrating creativity in various digital forms. By hosting events, exhibitions, and live performances in major cities worldwide, the Creators Project sought to foster a community where technology and artistic expression could merge, enabling new forms of creativity.",
    skills:['JS', 'iOS', 'Android', 'Ruby', 'PHP', 'Django'],
    image_carousel:[{src:'/assets/case-studies/creators/creatorsprojectdevices_FINAL_01.png', alt:"The Creators Project on several devices"}],
    client: {
        title: "The Creators Project",
        description: (<>
         <p>The Creators Project was an innovative global initiative launched in 2010 by Vice Media and Intel, aimed at celebrating and supporting creativity in various digital forms. Designed as a platform to bridge the gap between technology and the arts, the project provided a space for artists, musicians, filmmakers, designers, and innovators to showcase their work and collaborate on cutting-edge projects. It featured multimedia content, including immersive documentaries, interviews, and behind-the-scenes looks at creative processes. By hosting events, exhibitions, and live performances in major cities worldwide, the Creators Project sought to foster a community where technology and artistic expression could merge, enabling new forms of creativity. The platform also highlighted the transformative potential of digital tools and technologies, positioning them as essential components in the evolution of contemporary arts. Overall, the Creators Project was a vibrant celebration of technological innovation fueling artistic endeavors.
</p>
        </>
            ),
    },
    ourRole: {
        title: "Our Roles",
        description:(<>
        <div className="center">
                <img src="/assets/case-studies/creators/touchDisplay_Page_04.png" alt="a guest using the display"/>
            </div>
       <p>
            Dave was responsible for building The Creators Project software for Android and iOS mobile and tablet, and SmartTV.  In addition, Dave led development of the software used by guests at the Creators Party events in seven cities around the globe.
            These on-site technologies included touchscreen displays, wayfinding software, and live visual displays of photos and tweets occurring at the event.</p>
            <p>
            In addition to guiding the team of designers and engineers,  Dave architected the overall product, coded several of the features, and installed the pieces at various events.</p>
            
        
        </>)
    },
    challenges: [
        {
            title: "We want this on every device, everywhere",
            description: (<>
            <div className="center">
                <img src="/assets/case-studies/creators/touchDisplay_Page_08.png" alt="views of the creators project on ipad"/>
            </div>
                <p>One of my primary tasks was to develop the first iOS and Android applications for VICE, which were central to the success of The Creators Project. These applications had to provide a seamless user experience, allowing users to access a rich library of content, including articles, videos, and interactive features. I oversaw the entire application development lifecycle—from initial concept and design to coding, testing, and deployment. This system interfaced seamlessly with VICE’s CMS, automatically fetching and converting data into formats suitable for each platform. These were first ever experiences for Vice and Intel, and were used by millions around the globe.</p>
                </>)
          },
          {
            title: "The Creators Project's own displays should be at the same caliber as the artists they are representing",
            description: (<>
            <div className="center">
                <img src="/assets/case-studies/creators/touchDisplay_Page_03.png" alt="views of the creators project on ipad"/>
            </div>
            <p>Interactive Event Display systems were used to provide a fun wayfinding experience for thousands, and encourage earned media by displaying  displaying real-time event photos coupled with overlaid Twitter commentary during the events. These systems had to be function around the globe, and sometimes, as was the case in China, without internet.
            </p>
            <div className="center">
                <img src="/assets/case-studies/creators/touchDisplay_Page_05.png" alt="views of the creators project on ipad"/>
            </div>
            </>)
          },
          {
            title: "There are stakeholders and tastemakers, everyone needs to be happy",
            description: (<>
            
            <p>
                Working with both Vice Media and Intel required being able to communicate both technically and with cultural savvy.  In addition, the software was being used to represent internationally renowned artists. For example, Takashhi Murakami designed the panel boards which housed our interactive touch screens.  Understanding of priorities such as perfection of color pallete and frequent communication for all stakeholders was essential. Finally the pieces were a heavy focus of tastemakers that were invited to attend the events and write content for the sites.  The software experiences had to be elegant, novel technology, yet very simple to use.
                </p></>)
          }
    ]
};

const Emerge = {
    title: "Spotify's Emerge",
    tagline: '"A Battle of the Bands held in countries around the world"',
    statement:"Spotify's Emerge campaign discovers and promotes promising, yet under-recognized musical talents using extensive streaming data. Over ten weeks, the bands compete by amassing streams and social media engagement to ultimately win the top spot.  Emerge offers emerging talents a global stage, creating a novel approach for major brands to endorse up-and-coming artists.",
    skills:['JS', 'SQL', ' AI Algorithms', 'HTML5'],
    image_carousel:[{src:'/assets/case-studies/emerge/emerge1.jpg', alt:"Emerge in Germany"}],
    client: {
        title: "Spotify's Emerge",
        description: (
            <>  
            <div className="center">
                <video width="100%" height="auto" controls>
                    <source src="https://d3hsb46u10vabg.cloudfront.net/EmergeWalkthrough.mp4#t=01.4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>

                <p>
                Spotify's Emerge campaign, run for several years in the US, Europe, and LatAm, focuses on discovering and promoting upcoming musical talent. 
                The initiative uses Spotify's extensive streaming data to identify new artists who show significant promise but may not yet have widespread recognition. 
                By leveraging algorithms and user engagement metrics, Emerge uncovers hidden gems across diverse genres and geographies. </p>
                <p>Artists selected through the Emerge campaign receive strategic support from Spotify, including placement in influential playlists, promotional content, and visibility across the platform’s marketing channels. The aim is to provide these emerging artists with a substantial boost in their careers by connecting them with a global audience and key industry influencers. Additionally, Emerge often involves collaborations and partnerships with well-known artists, record labels, and music festivals to further amplify the reach and impact of the selected talents. Hence, Emerge underscores Spotify’s commitment to nurturing new music and supporting artist growth.	</p>
            </>
            ),
    },
    ourRole: {
        title: "Our Roles",
        description:(<>
        <div className="center">
                <img src="/assets/case-studies/emerge/emergeWireFrame.png" alt="wireframes of the emerge experience"/>
            </div>
       <p>
          Emerge was designed to capture and analyze performance metrics over time across various dimensions such as artist, location, and social engagement. 
          Dave concepted the various metrics Emerge would capture, wrote the scraping algorithm capable of parsing Tweets, Facebook, and Instagram to detect mentions and integrated 
          this data into a robust architecture of the database and backend services. This allowed for real-time updates and scoring as the contest progressed, ensuring a dynamic and 
          responsive user experience. Additionally, Dave led design and development teams to create interactive UI elements using cutting-edge animated SVGs, resulting in a rich and immersive interface 
          that could dynamically update with data changes, and encouraged continuous user interaction.
            </p><p>
             Dave also concepted and built an AI system to maintain a story arc and facilitate user engagement (see Challenges for more info). Following the project's acclaim in the US, Dave extended its reach by traveling to Spotify campuses in Europe, helping local teams replicate and customize the campaign's success for their respective markets.
        </p>
        </>)
    },
    challenges: [
        {
            title: "We need to create return engagement over the ten week campaign",
            description: (<>
            <div className="center">
                <img src="/assets/case-studies/emerge/emerge2.jpg" alt="a "/>
            </div>
                <p>To ensure that Emerge maintained its competitive edge throughout the campaign, Dave delved into research on reality TV show structures to understand how to keep audiences captivated. Borrowing techniques such as heightened tension through editing and creating story arcs, Dave conceptualized an AI-driven score-balancing algorithm. This algorithm dynamically adjusted the scores based on the relative performance of the artists, ensuring a close and engaging competition, even when disparities existed. The effectiveness of this approach was evident when Macklemore's enormous success could have overshadowed other contestants, but the system kept the audience engaged by maintaining a sense of suspense.
                </p>
                </>)
          },
          {
            title: "This needs to be reusable by various Spotify offices",
            description: (<>
            <p>The entire project was architected to be driven from a content management system we developed for Spotify and reusable around the globe.  This Spotify's marketing teams in different countries to roll out their own version of Emerge, add new artists, 
                select launch dates, and list the zones of their country which function for regional competitions.
            </p>
            <div className="center">
                <img src="/assets/case-studies/emerge/emerge3.jpg" alt="emerge tweets coming in"/>
            </div>
            </>)
          },
    ]
};


export const caseStudyData = [Xembly,Emerge, Creators];