import { useEffect } from 'react';
import EndQuestion from './endQuestion'
import './pages.scss'
export default function GetInTouch(){
    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if ((window as any).hbspt) {
                // @TS-ignore
                (window as any).hbspt.forms.create({
                    region: "na1",
                    portalId: "46169276",
                    formId: "73b3dee0-8973-4d1a-b7ff-77bdc0ba6b4e",
                    target: '#hubspotForm'
                })
            }
        });
    }, []);
    return (
        <div className="pageContainer">
        <div className="headerImg positionTop">
            <img src="/assets/pageImages/getintouch.png" alt="A robot sits in his dilapitated apartment. He picks up the red 1970s telephone and calls NullWest. Please help he says, finally feeling a sense of relief that all his tech problems will be solved." />
        </div>
        <div className='pageContent'>
            <h2>What are you working on?</h2>
            <div id="hubspotForm"></div>
              </div>
        <EndQuestion question="Prefer to schedule a chat now?" externalTab buttonText="book a meeting" link="https://calendar.app.google/PJh9gQ4QcGHsVXNV8" />
        </div>
    )
}