import EndQuestion from './endQuestion'
import './pages.scss'

const processList = [
    {   title: "Let's Meet",
        img:{
            src:'icons/process/handshake.svg'
        },
        text:(<div>We spend an hour getting to know each other better. We learn your business needs and how you hope an amazing software house (like Nullwest) can help.
        <br/><br/>Best part - <strong>it’s totally free! </strong></div>)
    },
    {   
        title: "Discovery",
        img:{
            src:'icons/process/lifesupport.svg'
        },
        text:(<div>The first 10% of your project is where we discover and plan out all of our project requirements and milestones.  We’ll also prototype any proof-of concepts to make sure we are on the right track. By the end, you know what will make this project a success and all the steps required to get there.</div>)
    },
    {   
        title: "Build",
        img:{
            src:'icons/process/handyman.svg'
        },
        text:(<div>When we build, we do it as an extension of your team. We recommend attending our daily scrums where we can review progress, discuss obstacles, and show off demos. We use an agile approach with continuous testing.</div>)
    },
    {   
        title: "Pre-Release",
        img:{
            src:'icons/process/checklist.svg'
        },
        text:(<div>We will work alongside you in preparation for product release. From beta tests to providing marketing materials, we aim to maximize product success and ease user adoption.</div>)
    },
    {   
        title: "Launch",
        img:{
            src:'icons/process/rocket.svg'
        },
        text:(<div>During the initial launch of your new product, we provide 24/7 support, monitor all development operations, and frequently review software performance and metrics. We'll also do a victory lap with you, celebrating our hard work together.</div>)
    },
    {   
        title: "Lifecycle Support",
        img:{
            src:'icons/process/pulse.svg'
        },
        text:(<div>Digital products require maintenance and monitoring throughout their lifecycle. We aim to build products that last decades by handling software updates, patching bugs, releasing new features, and maintaining robust documentation.</div>)
    },
]

const capabilities:{[key:string]:string[]} = {
    'frameworks':['nextjs', 'react', 'angular'],
    'languages':['javascript', 'typescript','python',"swift", "java", 'PHP' ],
    'AI & Machine Learning':['LLM integration', 'AI Pipelines', 'Recommendation Algorithms', 'web scraping', 'computer vision'],
    'R&D':['market analysis', 'UX design', 'rapid prototyping', 'product testing', ],
}

export default function ProcessCapabiliities(){

    const processCard=(title:string, img:{src:string}, text:any, cnt=0)=>{
       return( 
       <div className="processCard">
            <div className="header">
                <div className="counter">
                    <span>{cnt+1}</span>
                </div>
                <div className="title">{title}</div>
                <div><img src={img.src} width="64px" alt="" /> </div>
            </div>
            
            <div className="text">{text}</div>
        </div>
       )
    }

    return (
        <div className="pageContainer">
        <div className="headerImg">
            <img src="/assets/pageImages/processImage.png" alt="Image of a set of tools surrounding an iPad."/>
        </div>
        <div className='pageContent'>
            <div>
                <div className="hugeCallout pt-4">Our Process</div>
                <section>
                    <div className='processContainer'>
                    {processList.map((process, idx)=>{
                        return processCard(process.title, process.img, process.text, idx)
                    }
                    )}
                </div>
                </section>
                <div className="hugeCallout">Our Capabilities</div>
                <section>
                    <div className='capabilitiesCard'>
                        {Object.keys(capabilities).map((capability, idx)=>{
                            return (
                                <div key={`capability_${idx}`}>
                                    <div key={`capability_title_${idx}`} className="title">{capability}</div>
                                    {capabilities[capability].map((skill, idx)=>(
                                        <div key={`skill_${idx}`}className="skill">{skill}</div>
                                    ))}
                                </div>
                            )
                        })}
                    </div>
                </section>
            </div>
            <EndQuestion question="Want to learn more about how we build?" buttonText="get in touch" link="/get-in-touch" />
        </div>
        </div>
    )
}