'use client';
import "./index.scss"

export default function TextButton({text, handleClick}:{text:string, handleClick:Function|void}){

    return (
        <div className="textButton">
            <span onClick={()=>{
                if(handleClick) handleClick()
            }}>{text}</span>
        </div>
    )
}