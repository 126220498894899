import { Link } from 'wouter'
import EndQuestion from './endQuestion'
import './pages.scss'
export default function MeetFounders(){
    return (
        <div className="pageContainer">
            <div className="headerImg positionTop">
                <img src="/assets/pageImages/founders.png" alt="Photo of Scott and Dave working together in a lounge. They look so happy and excited to build amazing software." />
            </div>
            <div className='pageContent'>
            <div>
                <h2>About NullWest</h2>
                <p>At NullWest, we are proud of our craftsmen approach to software and to the past work we have built for clients.  
                        If you are curious to learn more about our beliefs as a company, please check out <Link href="/our-credo">our credo</Link>.
                    </p>
                    <p>NullWest founders, Scott and Dave, spent nearly a decade collaborating on software as colleagues at various 
                     companies in the Seattle area, and hacking on their hobby projects.  Through NullWest, they bring their
                     well-honed collaborative processes to select client projects and pursue commercializing some of their hobby ventures.</p>
                     
            </div>
            <div>
                <h2>Dave Jimison</h2>
                <h3>Co-Founder, Director of Innovations</h3>
                <p>
                 Prior to NullWest Dave ran the creative and software teams which developed firsts for giants in US media, including Spotify's first Year in Review, Vice Media's first iPhone app, and MTV's first recommendation algorithm. 
                 Dave has exhibited internationally with his art, received praise from BBC, CNN, and scorn from The Colbert Report. Dave holds a doctorate in Digital Media from Georgia Tech and has served as adjunct faculty
                 at The New School, Cornish College, and CUNY Staten Island.
                </p>
                <h2>Scott Johnson</h2>
                <h3>Co-Founder, Director of Technology</h3>
                <p>Scott is a senior software engineer with over 10 years of experience across mobile apps, backend systems, and AI. He has worked at startups where he was an early engineer building teams and architectures, and at tech giants like Microsoft and Apple. Scott is a
    proven innovator, receiving his first patent early in his career for delivering data over constrained bandwidth.
    His diverse background spans building products from scratch to delivering mission-critical applications used by millions. Scott brings technical depth combined with leadership skills to drive innovation, recruit top talent, and implement robust architectures</p>
            </div>
            <EndQuestion question="Curious to learn more about NullWest?" buttonText="get in touch" link="/get-in-touch" />
            </div>
        </div>
    )
}