import React, {useEffect, useState} from 'react';
import {Route, Switch} from 'wouter';
import Nav from './components/nav';
import Footer from './components/footer';
import Home from './components/pages/home';
import { useLocation } from "wouter";

import './styles/global.scss'
import './styles/layout.scss'
import ProcessCapabiliities from './components/pages/process-capabilities';
import MeetFounders from './components/pages/meet-founders';
import GetInTouch from './components/pages/getInTouch';
import OurCredo from './components/pages/ourCredo';
import CaseStudies from './components/pages/case-studies';

function App() {
  const [location, setLocation] = useLocation();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setIsMobile(window.innerWidth<601);
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); 

  const pageContainer = (
    <Switch>
    <Route path="/"><Home /></Route>
    <Route path="/process-capabilities"><ProcessCapabiliities /></Route>
    <Route path="/meet-the-founders"><MeetFounders /></Route>
    <Route path="/get-in-touch"><GetInTouch /></Route>
    <Route path="/our-credo"><OurCredo /></Route>
    <Route path="/case-studies"><CaseStudies /></Route>
    <Route path="/case-studies/:caseName">
      {params=><CaseStudies caseName={params.caseName}/>}
    </Route>
    <Route path="*">Oops! We don't have that page</Route>
    </Switch>
  )
  return (
  <div className={`${isMobile?'mobile':'desktop'} container`}>
   <Nav />
   {pageContainer}
    <Footer />
  </div>
  );
}

export default App;
