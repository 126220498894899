
import {Link, useLocation} from "wouter";
import './nav.scss' 
import { useState } from "react";

export default function Nav(){
    const [location, setLocation] = useLocation();
    const [expanded, setExpanded] = useState(false);

    const navItems = [
        {
            link:'/case-studies',
            label:'case studies'
        },
        {
            link:'/process-capabilities',
            label:'process & capabilities'
        },
        {
            link:'/meet-the-founders',
            label:'meet the founders'
        },
        {
            link:'/get-in-touch',
            label:'get in touch'
        },
        
    ]
    
    const navLinks = navItems.map((item, idx)=><div key={`nav${idx}`} className={`linkHover ${location === item.link?'selected':''}`}><Link key={`link_${idx}`} href={item.link}>{item.label}</Link></div>)
    navLinks.unshift( <div key="logo" className="logo"><Link href="/"><img src="/nullWestLogo.svg" alt="NullWest Logo" /></Link></div>)
    return(
        <>
        <div className="navBar">
            {navLinks}
        </div>
        <div className={`mobileNavBar ${expanded?'active':''}`} onClick={()=>setExpanded(!expanded)}>
            <div className="header">
                <img src="/nullWest-white-logo.png" alt="NullWest Logo" />
                <div className="menuIcon"><img src="/icons/menu.svg" alt="Menu"/></div>
            </div>
            <div className="linkedItems">
                <div key="home"><Link href="/">home</Link></div>
                {navItems.map((item, idx)=><div key={`nav${idx}`}><Link key={`link_${idx}`} href={item.link}>{item.label}</Link></div>)}
            </div>
        </div>
    </>

    )
}