import { navigate } from "wouter/use-browser-location";
import '../../styles/global.scss';
import TextButton from '../buttons/textButton';

export default function EndQuestion({question,buttonText, link, externalTab = false}:{question: string,buttonText:string,  link: string, externalTab?: boolean}){
    return(
        <div className="endQuestion">
            <h2>{question}</h2>
            {!externalTab?
            ( <TextButton text={buttonText} handleClick={()=>navigate(link, {replace:true})}/>):
            ( <a href={link} target='_blank' className="no-decoration"><TextButton text={buttonText} handleClick={()=>{
                //do nothing
            }}/>
            </a>
            
            )
            }
        </div>
    )
}