import { useState } from 'react';
import CaseStudy from '../caseStudy'
import Overlay from '../overlay'
import EndQuestion from './endQuestion'
import './pages.scss'
import { navigate } from 'wouter/use-browser-location';
import { caseStudyData } from '../../data/case-studies';
export default function CaseStudies({caseName}:{caseName?:string}){
   
    
    const selectCaseStudy=(caseName:string)=>{
        caseName = caseName.replaceAll('-', ' ');
        const caseStudy = caseStudyData.find((element) => element.title ===caseName)
        if(caseStudy) return <CaseStudy caseStudyData={caseStudy} showSummary={false}/>
        return (<div>Not Found</div>)
    }
   
    return (
        <div className="pageContainer">
            {caseName && 
            <Overlay onClose={()=>navigate('/case-studies')}>
                {selectCaseStudy(caseName)}
            </Overlay>
            }
            <div className="headerImg">
                <img src="/assets/pageImages/casestudies.png" alt="A drawing of left brain/right brain working together in harmony." />
            </div>
                <div className="pageContent">
                <div className="hugeCallout">Here's some of our past work</div>
                    <div>
                <p>
                We're showcasing projects that are first-of-their-kind, work that required us to invent new approaches, user interfaces, and software paradigms. 
                Each case study documents the unique challenges and successes, 
                our roles in the project, and the technologies we used.
                </p>
                </div>
                <section>
                {caseStudyData.map((caseStudy, idx)=><CaseStudy key={idx} caseStudyData={caseStudy} />)}
                </section>
        
                <EndQuestion question="Learn more about how we build." externalTab buttonText="book a meeting" link="https://calendar.app.google/PJh9gQ4QcGHsVXNV8" />
            </div>
        </div>
        
        
    )
}